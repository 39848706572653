import { projectNameUpperCase } from '../config';

const slMessages = {
  sl: {
    app: {
      language: 'Language',
      activateUser: 'Activate user',
      addToCart: 'Add to cart',
      addUser: 'Add user',
      cartInProcess: {
        heading: 'Items in your cart',
        content: 'You already have items in your shopping cart.',
      },
      changePage: 'Change page',
      changePassword: 'Change password',
      deactivateUser: 'Deactivate user',
      globalSearchPlaceholder: 'Search in catalog',
      go: 'Go',
      haveAQuestion: 'Have a question?',
      home: 'Home',
      initialSetup: 'Initial setup...',
      itemsPerPage: 'Items per page',
      logout: 'Logout',
      logoutConfirmation: 'Are you sure you want to log out?',
      pickDeliveryPlace: {
        helperText: 'Select the establishment to which the order will be delivered',
        label: 'Place of Delivery',
        requiredError: 'Please select an establishment',
      },
      pickShipping: {
        helperText: 'Select shipping type to be used for delivery',
        label: 'Shipping type',
        requiredError: 'Please select shipping type',
      },
      pickPayment: {
        helperText: 'Select payment method to be used for payment',
        shippingSelectionRequired: 'Before choosing a payment method, you need to choose the type of delivery',
        label: 'Payment method',
        requiredError: 'Please select payment method',
      },
      portal: 'portal',
      products: {
        stockQuantityInfo: {
          notOnStock: 'not in stock',
          notOnStockKnownWhenExpected: 'expected stock date {timing}',
          onRequest: 'On request',
          onStock: 'in stock',
          pcs: '{qty, plural, one {{qty} pieces in stock} few {{qty} pieces in stock} other {{qty} pieces in stock}}',
          pcs50to100: '50 to 100 pieces in stock',
          pcs100to500: '100 to 500 pieces in stock',
          pcsOver500: 'More than 500 pieces in stock',
        },
        showMore: 'Show more',
        showLess: 'Show less',
      },
      replace: 'Replace',
      termsAndConditions: 'General Terms and Conditions',
      termsAndConditionsPHT: `General Terms and Conditions of ${projectNameUpperCase}`,
      toHome: 'Go to home page',
      dataIsNotAvailable: 'Data is not currently available.',
    },
    bulkPurchase: {
      afterFormatControl: 'After format check',
      controlFormat: 'Format check',
      downloadSampleDocument: 'Download a template',
      noInputData: 'No input data is entered.',
      pageTitle: 'Bulk purchase',
      productsWereAdded: 'All indetified products were added to cart',
      textRows: {
        helperText:
          'Enter `PRODUCTCODE;QTY` or `PRODUCTCODEspaceQTY` values, eg `A03001006;9`. If there is more than 1 product, the next PRODUCT CODE must be on a new line. You can also use the option to import from Excel, by copying and pasting columns.',
        label: 'Text rows',
      },
      toCart: 'To cart',
      errors: {
        productNotFound: 'product not found',
      },
    },
    cartApprovalPage: {
      areYouSureToSend: 'Are you sure you want to send the order?',
      continueShopping: 'Continue shopping',
      pageTitle: 'Cart approval',
    },
    cartSummaryPage: {
      cheaperProducts: `{
        possibleProducts, plural,
        one {There is 1 product in the cart that can be bought cheaper}
        other {There are {possibleProducts} products in the cart that can be bought cheaper}
      }`,
      separateOrderRequired: 'The pallet quantity must be entered on a separate order',
      previewBeforeOrder: 'Preview before order',
    },
    cataloguePage: {
      title: 'All products',
      individualPrice: 'Individual price',
      item: {
        package: 'Package',
      },
      chooseManufacturer: 'Select a manufacturer',
      noMatch: 'Your selection contains no items.',
      orderBy: {
        code: 'Code',
        name: 'Name',
        priceAsc: 'Lowest price',
        priceDesc: 'Highest price',
      },
      orderDirection: {
        ascending: 'Ascending',
        descending: 'Descending',
      },
      searchPlaceholder: 'Search',
      warningButton: {
        hideCheaperProducts: 'Hide cheaper product variants',
        otherOptions: 'In the product detail you can buy other product variants',
        showCheaperProducts: 'Show cheaper product variants',
      },
    },
    clickToUpload: 'Click to select file',
    common: {
      ok: 'OK',
      cu3Price: 'Sale price without discount',
      page: 'page',
      items: 'items',
      email: 'E-mail',
      phone: 'Phone',
      country: 'Country',
      city: 'City',
      days: 'days',
      street: 'Street',
      zip: 'ZIP',
      priceLevel: 'Price level',
      paymentType: 'Payment type',
      responsiblePersonName: 'Responsible person',
      maturityPeriod: 'Maturation period',
      region: 'Region',
      currency: 'Currency',
      hideFilter: 'Hide filter',
      of: 'of',
      search: 'Search',
      confirmReset: 'Confirm reset',
      resetCart: 'Reset cart',
      buyForFreeDeliveryAmount: 'Buy for another {amount} ',
      buyForFreeDeliveryRest: 'and\u00a0get free shipping',
      youHaveFreeDelivery: 'You have free shipping',
      approveOrder: 'Approve order',
      approver: 'Approver',
      basePriceWithoutTax: 'Base price w/o VAT',
      basePriceWithTax: 'Base price with VAT',
      reccomendedPrice: 'Recommended price',
      yourPrice: 'Your price',
      buy: 'Buy',
      canceled: 'canceled',
      code: 'Code',
      company: 'Company',
      createOrder: 'Create order',
      date: 'Date',
      deliveredQuantity: 'Delivered quantity',
      documentNumber: 'Document number',
      parameterNotFound: 'Parametr value not found',
      EAN: 'EAN',
      pallet: 'On the pallet',
      yes: 'Yes',
      no: 'No',
      expiration: 'Expiration',
      externalOrderNumber: 'Your order number',
      free: 'free of charge',
      listPage: {
        table: {
          columns: {
            billingAddress: 'Billing Address',
            dateOfOrder: 'Date of order',
            deliveryAddress: 'Delivery Address',
            id: 'ID',
            orderNumber: 'Order number',
            state: 'Status',
            totalWithoutTax: 'Total w/o VAT',
            totalWithTax: 'Total with VAT',
          },
        },
      },
      lookup: 'Lookup',
      minimumOrderQuantity: 'Minimum order quantity',
      name: 'Name',
      newOrderConfirmation: 'New order confirmation',
      notInStock: 'Not in stock',
      orderDetail: 'Order detail',
      orderNumber: 'Order number',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      pricePerPiece: 'Price/piece',
      quantity: 'Quantity',
      regNo: 'Reg. No.',
      rejectOrder: 'Reject order',
      send: 'Send',
      supplierNote: 'Supplier note',
      taxNo: 'VAT ID',
      totalWithoutTax: 'Total w/o VAT',
      totalWithTax: 'Total with VAT',
      type: 'Type',
      username: 'Username',
      vat: 'VAT',
      vatDMIC: 'DMIC VAT',
      vatDMOC: 'DMOC VAT',
      waitinForConfirmation: 'waiting for confirmation',
      withoutTax: 'w/o VAT',
      withoutTaxPerPc: 'w/o VAT per pcs',
      weight: 'Weight',
      withTax: 'with VAT',
      withTaxPerPc: 'with VAT per pcs',
    },
    creditNotes: {
      actions: {
        addNewCreditNote: 'Add new credit note',
        all: 'All credits',
      },
      creditNote: {
        billingAddress: {
          helperText: '',
          helperTextPreview: '',
          label: 'Billing address',
        },
        invoiceDate: {
          helperText: '',
          helperTextPreview: '',
          label: 'Date Issued',
        },
        newEntity: 'New credit memo',
        number: {
          helperText: '',
          helperTextPreview: '',
          label: 'Number',
        },
        orderNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Request number',
        },
        total: {
          helperText: '',
          helperTextPreview: '',
          label: 'Total',
        },
        totalWithTax: {
          helperText: '',
          helperTextPreview: '',
          label: 'Total with VAT',
        },
      },
      downloadCreditNote: 'Download credit note',
      listPage: {
        table: {
          columns: {
            invoiceDate: 'Date of Issue',
          },
          filter: {
            number: 'Number',
          },
        },
        title: 'Credits',
      },
      scoringBlog: {
        creditNoteRowsBlogPreview: {
          columns: {
            priceUnit: 'Price/pc',
          },
          title: 'Credit Lines',
        },
      },
    },
    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Items',
      },
    },
    customerProfileCreditNotesPage: {
      filter: {
        number: 'Credit note number',
      },
      title: 'Credit notes',
    },
    customerProfileDocumentsPage: {
      pageTitle: 'Documents',
      title: 'Documents',
    },
    customerProfileDeliveryNotesPage: {
      pageTitle: 'Delivery notes',
      title: 'Delivery notes',
    },
    customerProfileInvoiceDetailPage: {
      downloadInvoice: 'Download invoice',
      searchByInvoiceNumber: 'Search by invoice number',
      subjectList: {
        title: 'Invoice Items',
      },
    },
    customerProfileInvoicesPage: {
      filter: {
        number: 'Invoice number',
      },
      title: 'Invoices',
    },
    customerProfileInvoicesAndCreditNotesPage: {
      filter: {
        number: 'Invoice or credit note number',
      },
      title: 'Invoices and credit notes',
    },
    customerProfileOrderDetailPage: {
      dialogTitle: 'Add shopping list to cart',
      actions: {
        import: 'Add to cart',
        rename: 'Rename',
      },
      phase: {
        label: 'Phase',
      },
      subjectList: {
        title: 'Order items',
      },
      totalWithTax: {
        label: 'Total with VAT',
      },
    },
    customerProfileOrdersConfirmationPage: {
      pageTitle: 'Orders to be aproved',
      title: 'Approval/Rejection',
    },
    customerProfileOrdersPage: {
      addUndeliveredItemsToCart: 'Add undelivered items to cart',
      areYouSureToAddUndeliveredItems: 'Do you really want to add undelivered items from the order to the cart?',
      title: 'Orders',
    },
    customerProfilePage: {
      allBranchesLoaded: 'All branches are shown',
      branchesUsers: 'Branches users',
      addNewBranch: 'Add new branch',
      changeData: {
        dialogTitle: 'Change data',
        label: 'Change data',
        messages: {
          error: 'Erorr while changing data',
          success: 'Data changed successfully',
        },
      },
      newBranch: {
        popupTitle: 'New branch request',
        success: `Dear customer, your request for a new branch has been successfully sent to ${projectNameUpperCase}, a.s. for approval and implementation.`,
        error: 'There was an error processing the request for a new branch.',
      },
      changePassword: {
        dialogTitle: 'Change password',
        confirmPassword: 'Entered passwords do not match',
        errorLoading: 'Cannot load the company data',
        label: 'Change password',
        passwordStrongEnough: [
          'The password is not strong enough. ',
          'The password must contain at least 8 characters, of which at least one is an uppercase letter, a lowercase letter and a number.',
          'Password must not contain spaces',
        ],
      },
      contactEmail: 'Contact email (username)',
      createMasterUser: 'Create master user',
      createUserBranches: 'Create user branches',
      customer: 'Customer',
      deliveryPlaces: {
        label: 'Assigned delivery places:',
      },
      user: 'User',
      fullName: 'Full name',
      loadingError: 'Cannot load user profile.',
      loadMoreBranches: 'Load more branches',
      masterUsers: 'Master users',
      title: 'User profile',
    },
    customerProfileShoppingListsPage: {
      title: 'Shopping lists',
      createShoppingList: 'Create shopping list',
      shoppingListName: 'Shopping list name',
      shoppingListHelperText: 'Under the given name you will find the shopping list in the list of shopping lists.',
      shoppingListCreate: 'New shopping list "{name}" was created',
      shoppingListDeleted: 'Shopping list "{name}" was deleted',
      shoppingListUpdated: 'Shopping list "{name}" was updated',
    },
    homePage: {
      downloadPriceLists: {
        downloadProblem: 'Failed to get information to download price lists.',
        title: 'Price lists for download',
        waitingMessage: 'Downloadable price lists will be available after they are generated.',
      },
      gotoShopping: 'Shopping',
      title: 'Home Page',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Add new invoice',
        all: 'All invoices',
      },
      invoice: {
        billingAddress: {
          helperText: '',
          helperTextPreview: '',
          label: 'Delivery address',
        },
        deliveryNoteNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Delivery note number',
        },
        invoiceDate: {
          helperText: '',
          helperTextPreview: '',
          label: 'Date Issued',
        },
        newEntity: 'New Invoice',
        number: {
          helperText: '',
          helperTextPreview: '',
          label: 'Number',
        },
        orderNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Request number',
        },
        paid: {
          helperText: '',
          helperTextPreview: '',
          label: 'Reimbursed',
        },
        total: {
          helperText: '',
          helperTextPreview: '',
          label: 'Total',
        },
        totalWithTax: {
          helperText: '',
          helperTextPreview: '',
          label: 'Total with VAT',
        },
      },
      listPage: {
        table: {
          columns: {
            billingAddress: 'Shipping Address',
            download: 'Download',
            dueDate: 'Due date',
            invoiceDate: 'Date of invoice',
            number: 'Invoice number',
            orderNumber: 'Request Number',
            total: 'Total',
            totalWithTax: 'Total with VAT',
            unpaidAmount: 'Unpaid amount',
          },
          ilter: {
            number: 'Number',
          },
        },
        title: 'Invoices',
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          columns: {
            priceUnit: 'Price/pc',
          },
          title: 'Invoice Lines',
        },
      },
    },
    loginPage: {
      advantageHeading: 'Effective solution for corporate purchases',
      bullets: {
        bullet1: 'Three-phase approval system',
        bullet2: 'Orders by product code',
        bullet3: 'Creating shopping lists',
        bullet4: 'Management of persons and centers',
        bullet5: 'Integration with our ERP system',
      },
      clothingBranding: 'Clothing branding',
      contact: {
        name: 'Pavel Nešetřil',
        role: 'production manager',
      },
      customerSupport: 'Customer support',
      discountedTransport: 'Discounted transport',
      enterYourEmail: 'Enter you email',
      forgotPassword: 'Forgot password',
      individualPrices: 'Individual prices',
      info: 'Modern trader working 24 hours a day, which allows you to set up an individual B2B environment from prices to the order approval system',
      login: 'Login',
      twoWordsLogin: 'Login',
      loginError: 'Login error.',
      messages: {
        errorUpdatingPassword: 'Error updating password.',
        passwordSent: 'Password has been sent to your email {email}.',
      },
      register: 'Register',
      toCreateAccount: 'To create a B2B account, contact us',
      wrongCredentials: 'Wrong username or password.',
    },
    loginPageRegistration: {
      registrationTitle: 'New Customer Registration',
      companyName: 'Company name',
      regNo: 'Registration Number',
      taxNo: 'Tax Number',
      address: 'Street',
      city: 'City',
      zipCode: 'ZIP code',
      country: 'Country',
      website: 'Website',
      optional: 'optional',
      contactPersonName: "Contact person's name",
      contactPersonPhone: "Contact person's phone",
      contactPersonEmail: "Contact person's e-mail",
      note: 'Note',
      houseNumber1: 'Descriptive number',
      houseNumber2: 'Orientational number',
      cancel: 'Cancel',
      sendRegistration: 'Send registration',
      close: 'Close',
      errors: {
        invalidCountryInTaxNo: 'The entered state does not match the state designation in the entered VAT number',
        invalidTaxNoPattern:
          'The VAT number entered is not in the correct format, the expected format is two capital letters and 8-10 digits',
      },
      responses: {
        success: 'Registration has been successfully submitted',
        error:
          'Unfortunately, a new company registration could not be completed. Please try again or contact our support.',
      },
    },
    logoutPage: {
      logoutError: 'Logout error.',
      logoutInProgress: 'Logging out...',
    },
    mainNavigation: {
      aboutUs: 'About us',
      allAboutShopping: 'All about shopping',
      allProducts: 'All products',
      allRightsReserved: 'All rights reserved',
      bulkPurchase: 'Bulk purchase',
      downloadDocuments: 'Download documents',
      home: 'Home',
      introduction: 'Introduction',
      products: 'Products',
      ecatalogue: 'E-Catalogue',
      tos: 'Terms and conditions',
      contacts: 'Contacts',
      download: 'Download',
      gdpr: 'GDPR',
    },
    notFoundPage: {
      text: 'The Page You Were Looking For Was Not Found',
    },
    orderDisapprovalPage: {
      dialogTitle: 'Order disapproval',
      reason: {
        helperText: 'The reason will be received by the author of the order',
        // label: 'Důvod pro zamítnutí objednávky',
        // helperText: 'Důvod bude obdržen autorem objednávky'
        label: 'Reason for order rejection',
      },
      title: 'Order rejection information has been sent',
      toCatalogue: 'Go to Catalog',
      toOrders: 'Go to Orders',
    },
    orders: {
      orderState: {
        created: 'Created',
        processed: 'Processed',
        completed: 'Completed',
        partiallyCompleted: 'Partially completed',
        canceled: 'Canceled',
      },
      listPage: {
        table: {
          columns: {
            applicant: 'Applicant',
            dateOfOrder: 'Date of order',
            download: 'Download',
            owner: 'Owner',
            state: 'Status',
            total: 'Total w/o VAT',
            totalWithTax: 'Total with VAT',
          },
        },
      },
      order: {
        dateOfOrder: {
          label: 'Date of order',
        },
        externalOrderNumber: {
          label: 'Your order number',
        },
        priceLimit: 'Order price limit',
      },
    },
    orderSentToApprovalPage: {
      pageTitle: 'Order sent to approval',
    },
    orderThankYouPage: {
      description: 'Below you will find information about your order.',
      notFromOrder: 'You are not coming from order process...',
      pageTitle: 'Thank you for your order',
      thankForOrder: 'Thank you for your order',
    },
    productDetailPage: {
      details: 'Details',
      documents: 'Documents',
      displayError: 'Error loading product details.',
      notAvailable: 'This product is no longer available.',
      limited: 'This item is subject to sales restrictions.',
      productQuestsion: 'Question about the product',
      question: 'Question',
      stockAvailability: 'Stock availability',
      imageNotAvailable: 'Image not available',
    },
    productPage: {
      minOrderableQuantity: 'Min. order quantity',
      parameters: 'Parameters',
      pcsInStock: 'in stock',
    },
    reactMuiTables: {
      filter: {
        actions: {
          resetFilter: 'Reset filter',
        },
      },
    },
    shoppingCart: {
      empty: 'Your cart is empty.',
      created: 'Empty cart created',
      continueInCreated: 'Continue in created cart',
      messages: {
        cartManipulations: {
          allRemoved: 'All items in the cart have been removed.',
          itemAdded: 'To cart {number, plural, one {item was added} other {items were added}}.',
          itemRemoved: 'From cart {number, plural, one {item was removed} other {items were removed}}.',
        },
      },
      order: 'Order',
      quantityField: {
        quantityRounded: 'Quantity was rounded to nearest package size.',
      },
      replacingProduct: 'Replacing product',
    },
  },
};

export default slMessages;

import { useCallback } from 'react';
import { atomFamily, selectorFamily, useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import { EntityId, Order, OrderRecord, OrderSubject } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, currencyState, pricesTypeState } from '../appState';
import { CatalogueProduct } from '../products/catalogue';
import {
  justifyQuantity,
  productQuantityLimitsDefaults,
  shoppingCartState,
  ShoppingCartUpdateCartDataItemParams,
  useUpdateCart,
} from '../shoppingCart';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export type OrderDetailOrder = Order<OrderRecord, 'contact' | 'fulfillments' | 'owner'>;

export const getOrderDetail = (
  apiClient: ApiClient,
  params: { id: EntityId; populate?: string[] },
): Promise<OrderDetailOrder> => {
  return apiClient.get<OrderDetailOrder, { id: EntityId }>('/v1/orders/:id', params);
};

export const orderDetailInitialStateFamily = GlobalStateRegister.register(
  atomFamily<OrderDetailOrder | undefined, EntityId>({
    key: 'orderDetailInitial',
    default: undefined,
  }),
  'orderDetailInitial',
);

export const orderDetailQuery = selectorFamily<OrderDetailOrder, EntityId>({
  key: 'orderDetail',
  get:
    (orderId) =>
    ({ get }) => {
      const initial = get(orderDetailInitialStateFamily(orderId));
      if (initial) return initial;

      const apiClient = get(apiClientState);

      return getOrderDetail(apiClient, { id: orderId, populate: ['contact', 'fulfillments'] });
    },
});

export const useOrderInsertToCart = (subjects: OrderSubject[] | undefined): (() => Promise<any>) => {
  const apiClient = useRecoilValue(apiClientState);
  const currency = useRecoilValue(currencyState);
  const pricesType = useRecoilValue(pricesTypeState);
  const cart = useRecoilValue(shoppingCartState);

  const updateCart = useUpdateCart();

  return useCallback<() => Promise<any>>(() => {
    invariant(cart, 'Cart not initialized!');
    invariant(subjects, 'Order subjects missing!');
    const productIds = subjects.map((i) => i.product.id);

    return apiClient
      .post<
        { products: CatalogueProduct[] },
        { currency: string; pricesType: 'B2B' | 'B2C' | 'B2BForeign' | undefined; products: string[] }
      >('/v1/catalogue/presents', {
        products: productIds,
        currency,
        pricesType,
      })
      .then(
        (res) =>
          res.products
            .map<ShoppingCartUpdateCartDataItemParams | undefined>((p) => {
              const found = subjects.find((s) => s.product.id === p.id);
              const inShoppingCart = cart.cartContent.subjects.find(
                (c) =>
                  (typeof c.product === 'string' && c.product === p.id) ||
                  (typeof c.product !== 'string' && c.product.id === p.id),
              );

              const productLimits = productQuantityLimitsDefaults(p);

              return found
                ? {
                    product: { ...p, ...productLimits },
                    quantity: justifyQuantity(productLimits, found.quantity + (inShoppingCart?.quantity || 0)),
                  }
                : undefined;
            })
            .filter(Boolean) as ShoppingCartUpdateCartDataItemParams[],
      )
      .then((res) => {
        updateCart(res);
      });
  }, [apiClient, cart, currency, pricesType, subjects, updateCart]);
};

export function useOrderDetailStateLabel(phase: string | undefined): string | undefined {
  const t = useTranslateWithFallback();
  if (['created', 'processed', 'partiallyCompleted', 'completed', 'canceled'].includes(phase || '')) {
    return t(`orders/orderState/${phase}`);
  }
  return phase;
}

import { projectNameUpperCase } from '../config';

const csMessages = {
  cs: {
    app: {
      resetFilters: 'Resetovat filtry',
      welcome: 'Vítejte',
      units: 'ks',
      language: 'Volba jazyka',
      activateUser: 'Aktivovat uživatele',
      addToCart: 'Vložení do košíku',
      addUser: 'Přidat uživatele',
      cartInProcess: {
        heading: 'Máte již rozpracovaný košík',
        content: 'Máte naplněný košík rozpracovanou objednávkou.',
      },
      changePage: 'Změnit stránku',
      changePassword: 'Změna hesla',
      deactivateUser: 'Deaktivovat uživatele',
      globalSearchPlaceholder: 'Hledat v celém katalogu',
      go: 'Přejít',
      haveAQuestion: 'Mám dotaz',
      home: 'Domů',
      initialSetup: 'Úvodní ustanovení...',
      itemsPerPage: 'Položek na stránku',
      logout: 'Odhlásit',
      logoutConfirmation: 'Opravdu se chcete odhlásit?',
      pickDeliveryPlace: {
        helperText: 'Vyberte provozovnu, do které bude objednávka doručena',
        label: 'Místo doručení',
        requiredError: 'Vyberte prosím provozovnu',
      },
      pickShipping: {
        helperText: 'Vyberte typ doručení, který má být využit k doručení objednávky',
        label: 'Typ doručení',
        requiredError: 'Vyberte prosím typ doručení',
      },
      pickPayment: {
        helperText: 'Vyberte platební metodu, která má být využita k platbě objednávky',
        shippingSelectionRequired: 'Před volbou platební metody je třeba zvolit typ doručení',
        label: 'Platební metoda',
        requiredError: 'Vyberte prosím platební metodu',
      },
      portal: 'portál',
      products: {
        stockQuantityInfo: {
          notOnStock: 'není skladem',
          notOnStockKnownWhenExpected: 'očekávané datum naskladnění {timing}',
          onRequest: 'Na dotaz',
          onStock: 'skladem',
          pcs: '{qty, plural, one {{qty} kus skladem} few {{qty} kusy skladem} other {{qty} kusů skladem}}',
          pcs50to100: '50 až 100 kusů skladem',
          pcs100to500: '100 až 500 kusů skladem',
          pcsOver500: 'Více než 500 kusů skladem',
        },
        showMore: 'Zobrazit více',
        showLess: 'Zobrazit méně',
      },
      replace: 'Nahradit',
      termsAndConditions: 'Všeobecné obchodní podmínky',
      termsAndConditionsPHT: `Všeobecné obchodní podmínky společnosti ${projectNameUpperCase}`,
      toHome: 'Na domovskou stránku',
      dataIsNotAvailable: 'Data nejsou momentálně dispozici',
    },
    dashboard: {
      overdueInAWeek: 'Blížící se faktury',
      overdue: 'Faktury po splatnosti',
      announcement: 'Oznámení',

      overdueHint: 'Pokud uhradíte červené, máte nárok na slevu za dobrou platební morálku',
      discountPM: 'Nárok na slevu za dobrou platební morálku:',
      blocked:
        'Z důvodu neuhrazených faktur není možné objednávat s platbou převodem, pouze s platbou v hotovosti. Pro řešení situace se prosím obraťte na naše obchodní oddělení.',
      todayIs: 'Dnes je {value}',
    },
    bulkPurchase: {
      errorNotFound: 'Kód {code} nebyl nalezen',
      errorNotEnough: 'Kód {code} není skladem v požadovaném množství. Celkové množství skladem: {availableQuantity}',
      validationErrorSummary: 'Neúspěšných položek: {count}',
      formatError: 'Neplatný formát',
      validationResultsTitle: 'Výsledky kontroly',
      afterFormatControl: 'Po kontrole formátu',
      controlFormat: 'Kontrola formátu',
      downloadSampleDocument: 'Stáhnout šablonu',
      noInputData: 'Nejsou zadána vstupní data.',
      pageTitle: 'Hromadné vložení',
      productsWereAdded: 'Všechny identifikované produkty byly přidány do košíku.',
      textRows: {
        helperText:
          'Vložte hodnoty `KODPRODUKTU;MNOZSTVI` nebo `KODPRODUKTUmezeraMNOZSTVI`, např. `A03001006;9`. Při více než 1 produktu, musí být další KÓD PRODUKTU na novém řádku. Můžete také využít možnost importu z Excelu, pomocí kopírování a následného vložení sloupců.',
        label: 'Textové řádky',
      },
      toCart: 'Do košíku',
      errors: {
        productNotFound: 'Produkt nenalezen.',
      },
    },
    cartApprovalPage: {
      areYouSureToSend: 'Opravdu chcete objednávku odeslat?',
      continueShopping: 'Pokračovat v nákupu',
      pageTitle: 'Schválení objednávky',
      confirmText: 'Opravdu chcete objednávku odeslat ke schválení?',
    },
    cartSummaryPage: {
      cheaperProducts: `{
        possibleProducts, plural,
        one {V košíku je 1 produkt, který se dá koupit levněji} 
        few {V košíku jsou {possibleProducts} produkty, které se dají koupit levněji} 
        other {V košíku je {possibleProducts} produktů, které se dají koupit levněji} 
      }`,
      separateOrderRequired: 'Množstevní jednotku paleta je potřeba zadat na samostatné objednávce',
      previewBeforeOrder: 'Přehled před objednáním',
    },
    cataloguePage: {
      title: 'Všechny produkty',
      individualPrice: 'Individuální cena',
      item: {
        package: 'Dostupnost',
      },
      chooseManufacturer: 'Vyberte výrobce',
      noMatch: 'Váš výběr neobsahuje žádné položky.',
      orderBy: {
        code: 'Kód',
        name: 'Název',
        priceAsc: 'Od nejlevnějšího',
        priceDesc: 'Od nejdražšího',
      },
      orderDirection: {
        ascending: 'Vzestupně',
        descending: 'Sestupně',
      },
      searchPlaceholder: 'Vyhledat',
      warningButton: {
        hideCheaperProducts: 'Skrýt levnější varianty produktu',
        otherOptions: 'V detailu produktu můžete nakoupit další varianty produktu',
        showCheaperProducts: 'Zobrazit levnější varianty produktu',
      },
    },
    clickToUpload: 'Klikněte pro zvolení souboru',
    common: {
      ok: 'OK',
      cu3Price: 'Prodejní cena bez slevy',
      items: 'položek',
      page: 'stránka',
      email: 'E-mail',
      phone: 'Telefon',
      phoneShort: 'Tel',
      country: 'Země',
      city: 'Město',
      zip: 'PSČ',
      days: 'dní',
      street: 'Ulice',
      priceLevel: 'Cenová úroveň',
      paymentType: 'Forma úhrady',
      responsiblePersonName: 'Odpovědná osoba',
      maturityPeriod: 'Splatnost',
      region: 'Region',
      currency: 'Měna',
      hideFilter: 'Skrýt filtr',
      of: 'z',
      search: 'Vyhledat',
      confirmReset: 'Potvrdit reset',
      resetCart: 'Resetovat košík',
      buyForFreeDeliveryAmount: 'Nakupte ještě za {amount} ',
      buyForFreeDeliveryRest: 'a\u00a0máte dopravu zdarma',
      youHaveFreeDelivery: 'Máte dopravu zdarma',
      approveOrder: 'Schválit objednávku',
      approver: 'Schvalovatel',
      basePriceWithoutTax: 'Základní cena bez DPH',
      basePriceWithTax: 'Základní cena s DPH',
      reccomendedPrice: 'Prodejní cena bez slevy',
      yourPrice: 'Vaše nákupní cena',
      branches: 'Prodejny',
      buy: 'Koupit',
      canceled: 'Zrušeno',
      code: 'Kód',
      company: 'Firma',
      createOrder: 'Vytvořit objednávku',
      date: 'Datum',
      deliveredQuantity: 'Dodané množství',
      documentNumber: 'Číslo dokumentu',
      parameterNotFound: 'Hodnota parametru nenalezena',
      EAN: 'EAN',
      pallet: 'Na paletě',
      yes: 'Ano',
      no: 'Ne',
      expiration: 'Expirace',
      externalOrderNumber: 'Vaše číslo požadavku',
      free: 'Zdarma',
      listPage: {
        table: {
          columns: {
            billingAddress: 'Fakturační adresa',
            dateOfOrder: 'Datum objednání',
            deliveryAddress: 'Adresa dodání',
            id: 'Číslo',
            orderNumber: 'Číslo požadavku',
            state: 'Stav',
            totalWithoutTax: 'Celkem',
            totalWithTax: 'Celkem s DPH',
          },
        },
      },
      lookup: 'Pohledávka',
      minimumOrderQuantity: 'Minimální objednatelné množství',
      name: 'Jméno',
      newOrderConfirmation: 'Potvrzení nové objednávky',
      notInStock: 'není skladem',
      orderDetail: 'Detail objednávky',
      orderNumber: 'Číslo požadavku',
      password: 'Heslo',
      passwordConfirm: 'Potvrzení hesla',
      pricePerPiece: 'Cena/ks',
      quantity: 'Množství',
      regNo: 'IČO',
      rejectOrder: 'Zamítnout objednávku',
      send: 'Odeslat',
      supplierNote: 'Poznámka pro dodavatele',
      taxNo: 'DIČ',
      totalWithoutTax: 'Celkem bez DPH',
      totalWithTax: 'Celkem s DPH',
      type: 'Typ',
      username: 'Uživatelské jméno',
      vat: 'DPH',
      vatDMIC: 'DMIC s DPH',
      vatDMOC: 'DMOC s DPH',
      waitinForConfirmation: 'Čeká na potvrzení',
      weight: 'Hmotnost',
      withoutTax: 'Bez DPH',
      withoutTaxPerPc: 'bez DPH za ks',
      withTax: 'S DPH',
      withTaxPerPc: 's DPH za ks',
    },
    creditNotes: {
      actions: {
        addNewCreditNote: 'Přidat nový dobropis',
        all: 'Všechny dobropisy',
      },
      creditNote: {
        billingAddress: {
          helperText: '',
          helperTextPreview: '',
          label: 'Fakturační adresa',
        },
        invoiceDate: {
          helperText: '',
          helperTextPreview: '',
          label: 'Datum vystavení',
        },
        newEntity: 'Nový dobropis',
        number: {
          helperText: '',
          helperTextPreview: '',
          label: 'Číslo',
        },
        orderNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Číslo požadavku',
        },
        total: {
          helperText: '',
          helperTextPreview: '',
          label: 'Celkem',
        },
        totalWithTax: {
          helperText: '',
          helperTextPreview: '',
          label: 'Celkem s DPH',
        },
      },
      downloadCreditNote: 'Stáhnout dobropis',
      listPage: {
        table: {
          columns: {
            invoiceDate: 'Datum vystavení',
          },
          filter: {
            number: 'Číslo',
          },
        },
        title: 'Dobropisy',
      },
      scoringBlog: {
        creditNoteRowsBlogPreview: {
          columns: {
            priceUnit: 'Cena/ks',
          },
          title: 'Řádky dobropisu',
        },
      },
    },
    customerProfileCreditNoteDetailPage: {
      subjectList: {
        title: 'Položky',
      },
    },
    customerProfileCreditNotesPage: {
      filter: {
        number: 'Číslo dobropisu',
      },
      title: 'Dobropisy',
    },
    customerProfileDocumentsPage: {
      pageTitle: 'Dokumenty',
      title: 'Dokumenty',
    },
    customerProfileDeliveryNotesPage: {
      pageTitle: 'Dodací listy',
      title: 'Dodací listy',
    },
    customerProfileInvoiceDetailPage: {
      downloadInvoice: 'Stáhnout fakturu',
      searchByInvoiceNumber: 'Vyhledat produkty faktury',
      subjectList: {
        title: 'Položky faktury',
      },
    },
    customerProfileInvoicesPage: {
      filter: {
        number: 'Číslo faktury',
      },
      title: 'Faktury',
    },
    customerProfileInvoicesAndCreditNotesPage: {
      filter: {
        number: 'Číslo faktury nebo dobropisu',
      },
      title: 'Faktury a dobropisy',
    },
    customerProfileOrderDetailPage: {
      dialogTitle: 'Vložit do košíku nákupní seznam',
      actions: {
        import: 'Vložit do košíku',
        rename: 'Přejmenovat',
      },
      phase: {
        label: 'Stav',
      },
      subjectList: {
        title: 'Položky objednávky',
      },
      totalWithTax: {
        label: 'Celkem s DPH',
      },
    },
    customerProfileOrdersConfirmationPage: {
      pageTitle: 'Objednávky ke schválení',
      title: 'Ke schválení/Zamítnutí',
    },
    customerProfileOrdersPage: {
      addUndeliveredItemsToCart: 'Přidat nedodané položky do košíku',
      areYouSureToAddUndeliveredItems: 'Opravdu chcete přidat nedodané položky z objednávky do košíku?',
      title: 'Objednávky',
    },
    customerProfilePage: {
      allBranchesLoaded: 'Byly zobrazeny všechny pobočky.',
      branchesUsers: 'Pobočky',
      addNewBranch: 'Vložit novou pobočku',
      newBranch: {
        popupTitle: 'Žádost o novou pobočku',
        success: `Vážený zákazníku, vaše žádost o zavedení nové dodací adresy byla úspěšně odeslána do společnosti ${projectNameUpperCase}, a.s. ke schválení a zavedení.`,
        error: 'Došlo k chybě při zpracování žádosti o novou pobočku.',
      },
      changeData: {
        dialogTitle: 'Změna údajů',
        label: 'Změnit údaje',
        messages: {
          error: 'Došlo k chybě při změně údajů',
          success: 'Vaše údaje byli úspěšně změněny',
        },
      },
      updatePhone: {
        dialogTitle: 'Změna telefonního čísla',
        requiredDialog: 'Zadejte prosím telefonní číslo',
      },
      changePassword: {
        dialogTitle: 'Změna hesla',
        confirmPassword: 'Zadaná hesla se neshodují',
        label: 'Změnit heslo',
        passwordStrongEnough: [
          'Heslo není dostatečně silné. ',
          'Heslo musí obsahovat alespoň 8 znaků, z nichž je alespoň jeden velké písmeno, malé písmeno a číslo.',
          'Heslo nesmí obsahovat mezery.',
        ],
      },
      contactEmail: 'Kontaktní email (Uživatelské jméno)',
      createMasterUser: 'Vytvořit master uživatele ',
      createUserBranches: 'Vytvořit uživatele pobočky',
      customer: 'Zákazník',
      deliveryPlaces: {
        label: 'Přiřazená místa určení:',
      },
      user: 'Uživatel',
      errorLoading: 'Nemůžu zobrazit data o společnosti',
      fullName: 'Jméno a příjmení',
      loadingError: 'Nemůžu zobrazit informace o nepřihlášeném uživateli.',
      loadMoreBranches: 'Načíst další pobočky',
      masterUsers: 'Master uživatelé',
      title: 'Uživatelský profil',
    },
    customerProfileShoppingListsPage: {
      title: 'Nákupní seznamy',
      createShoppingList: 'Vytvoření nákupního seznamu',
      shoppingListName: 'Název nákupního seznamu',
      shoppingListHelperText: 'Pod zadaným názvem nákupní seznam naleznete v seznamu nákpních seznamů.',
      shoppingListCreate: 'Byl vytvořený nový nákupní seznam {name}',
      shoppingListDeleted: 'Nákupní seznam "{name}" byl smazán',
      shoppingListUpdated: 'Nákupní seznam "{name}" byl aktualizován',
    },
    homePage: {
      downloadPriceLists: {
        downloadProblem: 'Nezdařilo se získlat informace k stažení ceníků.',
        title: 'Ceníky ke stažení',
        waitingMessage: 'Ceníky ke stažení budou k dipozici po jejich vygenerovaní.',
      },
      gotoShopping: 'Nakupovat',
      title: 'Úvodní stránka',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Přidat novú fakturu',
        all: 'Všechny fakturys',
      },
      invoice: {
        billingAddress: {
          helperText: '',
          helperTextPreview: '',
          label: 'Dodací adresa',
        },
        deliveryNoteNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Číslo dodacího listu',
        },
        invoiceDate: {
          helperText: '',
          helperTextPreview: '',
          label: 'Datum vystavení',
        },
        newEntity: 'Nová faktura',
        number: {
          helperText: '',
          helperTextPreview: '',
          label: 'Číslo',
        },
        orderNumber: {
          helperText: '',
          helperTextPreview: '',
          label: 'Číslo požadavku',
        },
        paid: {
          helperText: '',
          helperTextPreview: '',
          label: 'Uhrazená',
        },
        total: {
          helperText: '',
          helperTextPreview: '',
          label: 'Celkem',
        },
        totalWithTax: {
          helperText: '',
          helperTextPreview: '',
          label: 'Celkem s DPH',
        },
      },
      listPage: {
        table: {
          columns: {
            billingAddress: 'Dodací adresa',
            download: 'Stáhnout',
            amount: 'Částka',
            daysTillOverdue: 'Dní do splatnosti',
            dueDate: 'Datum splatnosti',
            invoiceDate: 'Datum vystavení',
            number: 'Číslo',
            orderNumber: 'Číslo požadavku',
            total: 'Celkem',
            totalWithTax: 'Celkem s DPH',
            unpaidAmount: 'Neuhrazená částka',
          },
          filter: {
            number: 'Číslo',
          },
        },
        title: 'Faktury',
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          columns: {
            priceUnit: 'Cena/ks',
          },
          title: 'Řádky faktury',
        },
      },
    },
    loginPage: {
      advantageHeading: 'Efektivní řešení firemních nákupů',
      bullets: {
        bullet1: 'Třífázový schválovací systém',
        bullet2: 'Objednávky dle kódu produktu',
        bullet3: 'Vytváření nákupních seznamů',
        bullet4: 'Správa osob a středisek',
        bullet5: 'Propojení s naším ERP systémem',
      },
      clothingBranding: 'Branding oděvů',
      contact: {
        name: 'Pavel Nešetřil',
        role: 'provozní manažer',
      },
      customerSupport: 'Zákaznická podpora',
      discountedTransport: 'Zvýhodněná doprava',
      enterYourEmail: 'Zadejte svůj přihlašovací email',
      forgotPassword: 'Zapomenuté heslo',
      individualPrices: 'Individuální ceny',
      info: 'Moderní obchodník pracující 24 hodin denně, který umožňuje nastavit individuální B2B prostředí a to od cen až po schvalovací systém objednávek',
      login: 'Přihlásit',
      twoWordsLogin: 'Přihlásit se',
      loginError: 'Chyba přihlášení.',
      messages: {
        errorUpdatingPassword: 'Nepodařilo se obnovit heslo.',
        passwordSent: 'Heslo bylo odesláno na Váš e-mail {email}.',
      },
      register: 'Registrovat',
      toCreateAccount: 'Pro založení B2B účtu nás kontaktujte',
      wrongCredentials: 'Nesprávné jméno nebo heslo.',
    },
    loginPageRegistration: {
      registrationTitle: 'Registrace nového zákazníka',
      companyName: 'Název firmy',
      regNo: 'IČO',
      taxNo: 'DIČ',
      address: 'Ulice',
      city: 'Město',
      zipCode: 'PSČ',
      country: 'Země',
      website: 'Adresa webových stránek',
      optional: 'nepovinné',
      contactPersonName: 'Jméno kontaktní osoby',
      contactPersonPhone: 'Telefon kontaktní osoby',
      contactPersonEmail: 'E-mail kontaktní osoby',
      note: 'Poznámka',
      houseNumber1: 'Číslo popisné',
      houseNumber2: 'Číslo orientační',
      cancel: 'Zrušit',
      sendRegistration: 'Odeslat registraci',
      close: 'Zavřít',
      errors: {
        invalidCountryInTaxNo: 'Zadaný stát se neshoduje s ozančením státu v zadaném DIČ',
        invalidTaxNoPattern:
          'Zadané DIČ není ve správném formátu, očekávaný formát představuje dvě velká písmena a 8-10 číslic',
      },
      responses: {
        success: 'Registrace byla úspěšně odeslána',
        error:
          'Registrace nové společnosti bohužel nebylo možné dokončit. Prosím opakujte proces, případně kontaktujte naší podporu.',
      },
    },
    logoutPage: {
      logoutError: 'Při odhlašování došlo k chybě!',
      logoutInProgress: 'Probíhá odhlášení',
    },
    mainNavigation: {
      dashboard: 'Dashboard',
      menu: 'Navigace',
      catalogueMenu: 'Nákupní katalog',
      aboutUs: 'O nás',
      allAboutShopping: 'Vše o nákupu',
      allProducts: 'Všechny produkty',
      allRightsReserved: 'Všechna práva vyhrazena',
      bulkPurchase: 'Hromadné vložení',
      downloadDocuments: 'Dokumenty ke stažení',
      home: 'Domů',
      introduction: 'Úvod',
      products: 'Zboží',
      ecatalogue: 'E-katalog',
      tos: 'Obchodní podmínky',
      contacts: 'Kontakty',
      download: 'Ke stažení',
      gdpr: 'GDPR',
    },
    notFoundPage: {
      text: 'Stránka nenalezena',
    },
    orderDisapprovalPage: {
      dialogTitle: 'Zamítnutí objednávky',
      reason: {
        helperText: 'Důvod bude obdržen autorem objednávky',
        label: 'Důvod pro zamítnutí objednávky',
      },
      title: 'Informace o zamítnutí objednávky byla zaslána',
      toCatalogue: 'Přejít na katalog',
      toOrders: 'Přejít na objednávky',
    },
    orders: {
      orderState: {
        created: 'Přijatá',
        processed: 'Zpracovává se',
        completed: 'Odeslaná',
        partiallyCompleted: 'Částečně zpracovaná',
        canceled: 'Stornovaná',
      },
      listPage: {
        table: {
          columns: {
            applicant: 'Žadatel', // dateOfOrder: 'Date of order',
            // download: 'Download',
            owner: 'Vlastník',
            // state: 'State',
            // total: 'Total w/o VAT',
            totalWithTax: 'Celkem',
            dateOfOrder: 'Datum objednání',
          },
        },
      },
      order: {
        dateOfOrder: {
          label: 'Datum objednání',
        },
        externalOrderNumber: {
          label: 'Vaše číslo požadavku',
        },
        priceLimit: 'Limit ceny objednávky',
      },
    },
    orderSentToApprovalPage: {
      pageTitle: 'Objednávka byla odeslána ke schválení',
    },
    orderThankYouPage: {
      description: 'Níže najdete informace ke své objednávce.',
      notFromOrder: 'Nepřicházíte z procesu objednání...',
      pageTitle: 'Děkujeme za vaši objednávku',
    },
    productDetailPage: {
      details: 'Podrobnosti',
      documents: 'Dokumenty',
      displayError: 'Chyba zobrazeni detailu produktu...',
      notAvailable: 'Tento produkt již není v nabídce',
      limited: 'Na tuto položku se vztahují restrikce na omezení prodeje.',
      productQuestsion: 'Dotaz k produktu',
      question: 'Dotaz',
      stockAvailability: 'Skladová dostupnost',
      imageNotAvailable: 'Obrázek není k dispozici',
    },
    productPage: {
      minOrderableQuantity: 'Min.Obj.Mn.',
      parameters: 'Parametry',
      pcsInStock: 'kusů skladem',
    },
    reactMuiTables: {
      filter: {
        actions: {
          resetFilter: 'resetovat filtr',
        },
      },
    },
    shoppingCart: {
      empty: 'Váš košík je prázdný',
      created: 'Byl vytvřen prázdný košík',
      continueInCreated: 'Pokračujete v rozpracovaném košíku',
      messages: {
        cartManipulations: {
          allRemoved: 'Všechny položky košíku byly odstraněny.',
          itemAdded: 'Do košíku {number, plural, one {byla přidána položka} other {byly přidány položky}}.',
          itemRemoved: 'Z košíku {number, plural, one {byla odstraněna položka} other {byly odstraněny položky}}.',
        },
      },
      order: 'Objednat',
      quantityField: {
        quantityRounded: 'Množství bylo zaokrouhledno na nejbližší velikost balení.',
      },
      replacingProduct: 'Nahrazení produktu',
    },
  },
};

export default csMessages;
